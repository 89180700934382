// @file Handles error reporting
import { browserCan, browserIs } from '@@/bits/browser'
import window from '@@/bits/global'
import type { FetchError } from '@padlet/fetch'
import { Dedupe, ExtraErrorData } from '@sentry/integrations'
import type { Event, EventHint } from '@sentry/types'
import type { BrowserOptions, StackFrame } from '@sentry/vue'
import {
  captureException as sentryCaptureException,
  configureScope,
  init as sentryInit,
  Integrations,
  withScope,
} from '@sentry/vue'

const API_KEY = 'https://7fbb2288ec794bef8ae6cad3689c63ba@o253203.ingest.sentry.io/22080'

// Sentry hijacks all console errors sometimes. Turning this on and off allows us to debug.
const ENABLED = true

interface ScopeOptions {
  cookies?: string
  locale?: string
  userId?: number
  fetchingMode?: string
}

interface InitOptions extends ScopeOptions {
  vue?: any
  environment?: string
  release?: string
}

interface CaptureOptions {
  level?: string
  context?: any
}

const allowUrls = [/padlet\.net/]

// Ignore specific errors and only send events for URLs that we care about
// @see https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
const ignoreErrors = []
const denyUrls = [
  // Some users save the Padlet page and load it on their file system. We ignore these errors
  /^file:\/\/\//i,
]

const messagesToExclude: string[] = [
  // Mobile Safari and iOS throw this for some reason we don't yet understand.
  // https://sentry.io/organizations/padlet-y9/issues/1050542665/events/42380bd67ff447798e402cd9121562c4/?project=22080
  'WebKit encountered an internal error',
  // Mobile Safari and iOS throw this for some reason we don't yet understand.
  // https://sentry.io/organizations/padlet-y9/issues/1050542665/events/f4ca2db73b0b46d1af66b5c50af14a7c/?project=22080
  'Software caused connection abort',
  // Mobile Safari and iOS throw this for some reason we don't yet understand.
  // https://sentry.io/organizations/padlet-y9/issues/1050542665/events/37ea064939a246fb990678403387dd90/?project=22080
  'The network connection was lost',
  // https://sentry.io/organizations/padlet-y9/issues/1303001659/?project=22080
  'Non-Error promise rejection captured with keys',
  // https://padlet-y9.sentry.io/share/issue/6fbfdc62161b4b2ca53cfb3b678afae7
  'zaloJSV2 is not defined',
  // https://padlet-y9.sentry.io/share/issue/8e1f8a66f4d24728b3883b9eac12ee20
  'Failed to fetch',
  // https://padlet-y9.sentry.io/share/issue/92fd4eafe229462f9b4df8858cdc8895/
  'AbortError',
  // If network is spotty, there's nothing we can do with this error
  // https://padlet-y9.sentry.io/share/issue/616672cc0fe9440c9a830c8484088a98/
  'UploadError',
  'UploadError: UploadError',
  // https://padlet-y9.sentry.io/share/issue/54c1e64416ad421fbaa3043f42502a61/
  'FetchLinkError',
  // https://sentry.io/answers/load-failed-javascript/
  'Load failed',
]

function isFrameCaptureFunction(frame: StackFrame): boolean {
  const frameFunction = frame.function
  if (!frameFunction) return false
  return frameFunction.includes('captureMessage') || frameFunction.includes('captureException')
}

// Turn this on if sentry breadcrumbs are being unhelpful.
// function beforeBreadcrumb (breadcrumb: Breadcrumb): Breadcrumb|null {
//   return breadcrumb.category === 'sentry' ? null : breadcrumb
// }

function beforeSend(event: Event, hint?: EventHint): Event | null {
  if (browserIs('ie')) return null
  if (event?.exception?.values != null && event?.exception?.values[0].type === 'UnhandledRejection') {
    return null
  }

  // Flag capturing-related function that happens in this file instead of the app as in_app=false
  // Sentry logs will look better, showing the correct file/function the error is actually on
  if (event.exception?.values != null) {
    const frames = event.exception?.values[0].stacktrace?.frames
    if (frames != null && frames.filter(isFrameCaptureFunction).length > 0) {
      let lastFrameIndex = frames.length - 1
      // Flag all frames after the capture function call, inclusive
      do {
        frames[lastFrameIndex].in_app = false
        lastFrameIndex -= 1
        if (isFrameCaptureFunction(frames[lastFrameIndex]) || lastFrameIndex === 0) break
      } while (true)
    }
  }

  // Fix issue #1504 in github.com/padlet/mozart
  // Only allow this event through on the "staging" environment.
  if (event.environment !== 'staging' && hint != null && hint.originalException) {
    let message = ''
    if (typeof hint.originalException === 'string') {
      message = hint.originalException
    } else {
      const errorMessage = (hint.originalException as any).message
      if (errorMessage) message = errorMessage
    }

    if (message) {
      let excludeMessage = false
      // I know for loop might be better here but typescript doesn't like loops
      messagesToExclude.forEach((messageToExclude): void => {
        if (!excludeMessage && message.startsWith(messageToExclude)) {
          excludeMessage = true
        }
      })
      if (excludeMessage) return null
    }
  }
  return event
}

function setScope(options: ScopeOptions = {}): void {
  configureScope((scope): void => {
    for (const key in options) {
      if (key == 'userId') {
        scope.setUser({ id: options?.userId?.toString() })
      } else {
        scope.setTag(key, options[key])
      }
    }
  })
}

function init(options: InitOptions): void {
  const skipSentry = window.location.protocol === 'file:'
  if (skipSentry) {
    console.error('Skipping Sentry initialization')
    return
  }

  const sentryOptions: BrowserOptions = {
    dsn: API_KEY,
    environment: options.environment ?? process?.env?.NODE_ENV ?? 'development',
    release: options.release ?? 'unknown',
    defaultIntegrations: false,
    beforeSend,
    ignoreErrors,
    denyUrls,
    allowUrls,
  }

  // This is the default, but we are keeping the list here to be more aware of what's happening.
  const integrations: any[] = [
    new Integrations.InboundFilters(),
    new Integrations.FunctionToString(),
    new Integrations.TryCatch(),
    new Integrations.Breadcrumbs({ sentry: false }),
    new Integrations.GlobalHandlers({
      onerror: true,
      onunhandledrejection: sentryOptions.environment !== 'production',
    }),
    new Integrations.LinkedErrors(),
    new Integrations.HttpContext(),
    new ExtraErrorData(),
    new Dedupe(),
  ]

  sentryOptions.integrations = integrations

  const vueOptions = {
    Vue: options.vue,
    attachProps: true,
    logErrors: true, // let error surface to console
  }

  if (ENABLED) {
    sentryInit({ ...sentryOptions, ...vueOptions })
    setScope({
      cookies: browserCan('cookies') ? 'yes' : 'no',
      ...(options.userId && { userId: options.userId }),
      ...(options.locale && { locale: options.locale }),
    })
  }
}

function captureException(e: any, options: CaptureOptions = {}): void {
  console.error(e)
  if (ENABLED) {
    withScope((scope): void => {
      if (options.context) {
        Object.keys(options.context).forEach((key): void => {
          scope.setExtra(key, options.context[key])
        })
      }
      sentryCaptureException(e)
    })
  }
}

function toTitleCase(str: string): string {
  return str.slice(0, 1).toUpperCase() + str.slice(1, str.length)
}

/**
 * Helper function to get error name for tracking on Sentry
 * from generic error message
 * @param message
 * @return name for Error class
 * @example getErrorName("Fail to fetch link") // "FailToFetchError"
 */
function getErrorName(message: string): string {
  const words = message.split(' ')
  const MAX_ERROR_NAME_LENGTH = 8
  let errorName = ''
  for (let i = 0; i < words.length; i++) {
    errorName = errorName + toTitleCase(words[i])
    if (errorName.length >= MAX_ERROR_NAME_LENGTH) {
      break
    }
  }
  if (!errorName.includes('Error')) {
    errorName = errorName + 'Error'
  }
  return errorName
}

/**
 * Create Error object with a class name
 * @param name - class name (e.g. CreatedPostError)
 * @param message
 * @return error object
 * @example createNamedError("FailToFetchError", "Fail to fetch link for image attachment") // returns FailToFetchError that is a Error object
 */
function createNamedError(name: string, message: string): Error {
  const instance = new Error(message)
  instance.name = name
  return instance
}

/** Capture generic message as Error on sentry */
function captureMessage(message: string, options: CaptureOptions = {}): void {
  console.warn(message)
  if (ENABLED) {
    const errorName = getErrorName(message)
    withScope((scope): void => {
      if (options.context) {
        Object.keys(options.context).forEach((key): void => {
          scope.setExtra(key, options.context[key])
        })
      }
      sentryCaptureException(createNamedError(errorName, message))
    })
  }
}

// See @padlet/fetch.
function isNetworkFetchError(error: FetchError): boolean {
  return error.originalError != null || error.status === 0
}

function captureFetchException(e: FetchError, extraContext: { [key: string]: any } = {}): void {
  const context = {
    status: e.status,
    error: e.originalError,
    online: window?.navigator?.onLine,
    ...extraContext,
  }
  captureException(e, { context })
}

// We can't do much if the user has a spotty internet connection. No point flooding our error tracker.
function captureNonNetworkFetchError(error: FetchError, extraContext: { [key: string]: any } = {}): void {
  if (isNetworkFetchError(error)) return
  captureFetchException(error, extraContext)
}

function trackFailedIcons(delayAfterPageLoad = 10000): void {
  try {
    const isLigatureIcon = (node): boolean => {
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      if (context == null) {
        return true
      }
      const word = node.textContent
      if (!word) return false

      const font = `24px "immaterial"`

      context.font = font
      const size = context.measureText(word)
      return size.width < 30
    }
    setTimeout(() => {
      const iNodes = Array.from(document.querySelectorAll('i.immaterial-icons'))
      const failedIcons = iNodes.filter((node) => !isLigatureIcon(node))
      const numberOfFailedIcons = failedIcons.length
      if (numberOfFailedIcons > 0) {
        captureMessage(`Failed to load immaterial icons on page`, {
          context: { first: failedIcons[0].outerHTML, count: `${numberOfFailedIcons}/${iNodes.length}` },
        })
      }
    }, delayAfterPageLoad)
  } catch (err) {
    captureMessage(`Cannot test icons to track failed icons`)
  }
}

export {
  captureException,
  captureFetchException,
  captureMessage,
  captureNonNetworkFetchError,
  init,
  isNetworkFetchError,
  setScope,
  trackFailedIcons,
}
