/**
 * @file Reads the lang attribute from the html object to detect the language.
 */
const hyphenatedCurrentLocale = document.documentElement.lang || 'en'
const underscoredCurrentLocale = hyphenatedCurrentLocale.replace(/-/g, '_')
const lang = hyphenatedCurrentLocale.split('-')[0]
const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
const currentCountryIso31662 = document.documentElement.getAttribute('data-country') ?? 'XX'

// The current long-form time zone name, eg "Singapore Standard Time". If not supported, returns tz (IANA)
// https://stackoverflow.com/a/67363188
const timeZoneName =
  new Intl.DateTimeFormat([], { timeZoneName: 'long' })
    .formatToParts(new Date())
    .find((part) => part.type === 'timeZoneName')?.value ?? tz

// we have functions to get the current locale so that we can get it when the function is called
// when full client surface is on this lets us get latest value as it may be stale
const getHyphenatedCurrentLocale = (): string => {
  const locale = document.documentElement.lang || 'en'
  if (locale === 'auto') {
    return 'en'
  }
  return locale
}

const getUnderscoredCurrentLocale = (): string => getHyphenatedCurrentLocale().replace(/-/g, '_')

export {
  currentCountryIso31662,
  getHyphenatedCurrentLocale,
  getUnderscoredCurrentLocale,
  hyphenatedCurrentLocale,
  lang,
  timeZoneName,
  tz,
  underscoredCurrentLocale,
}
