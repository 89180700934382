/**
 * @file bit to work with cookies
 */

const getCookie = (name: string): string => {
  const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)')
  return v != null ? v[2] : ''
}

interface SetCookieOptions {
  maxAgeInSeconds?: number
  expires?: string
  path?: string
}

function buildCookieString(name: string, value: string, options: SetCookieOptions = {}): string {
  const maxAgeString = options.maxAgeInSeconds ? `;max-age=${options.maxAgeInSeconds}` : ''
  const expiresString = options.expires ? `;expires=${options.expires}` : ''
  const pathString = options.path ? `;path=${options.path}` : ';path=/'
  return `${name}=${value}${maxAgeString}${expiresString}${pathString}`
}

function setCookie(name: string, value: string, options: SetCookieOptions = {}): void {
  document.cookie = buildCookieString(name, value, options)
}

function deleteCookie(name: string): void {
  setCookie(name, '', {
    maxAgeInSeconds: 0,
    expires: 'Thu, 01 Jan 1970 00:00:00 GMT',
    path: '/',
  })
}

export { buildCookieString, deleteCookie, getCookie, setCookie }
