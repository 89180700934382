/**
 * @file Internationalization helper.
 */
// Dynamically replaces variables after a translation happens
import { getHyphenatedCurrentLocale } from '@@/bits/current_locale'

declare global {
  interface Window {
    $intl: Record<string, any>
  }
}
interface Message {
  phrase?: string
  context?: string
  singularPhrase?: string
  pluralPhrase?: string
}

const EN = 'en'
const UNDERSCORE_EN = '-e'
let allTranslations = {}
let currentLocale = EN
let currentLocaleTranslations = {}
let pluralRules = new Intl.PluralRules(EN)

const refreshCurrentTranslations = (): void => {
  let translations = {}
  if (currentLocale !== EN) {
    if (allTranslations[currentLocale]) {
      translations = allTranslations[currentLocale]
    } else if (currentLocale.includes('-')) {
      const genericLocale = currentLocale.split('-').shift()
      const keys = Object.keys(allTranslations)
      for (const key of keys) {
        if (key.split('-').shift() === genericLocale) {
          translations = allTranslations[key]
          break
        }
      }
    }
  }
  currentLocaleTranslations = translations
}

export const setLocale = (locale: string): void => {
  currentLocale = locale.replace('_', '-')
  pluralRules = new Intl.PluralRules(locale === UNDERSCORE_EN ? EN : locale)
  refreshCurrentTranslations()
}

export const addTranslations = (translations: Record<string, any> = {}): void => {
  allTranslations = Object.assign({}, allTranslations, translations)
  refreshCurrentTranslations()
}

const interpolate = (text: string, args?: Record<string, any>): string => {
  if (args == null) return text
  return text.replace(/%?\{(.*?)}/g, (match, arg) =>
    args[arg] === undefined || args[arg] === null ? match : args[arg],
  )
}

const logMissing = (tkey: string): void => {
  // TODO: Implement. No point right now cuz we know many are missing.
}

const getTranslationKey = (message: Message): string => {
  let key = message.phrase
  if (!key) key = `{COUNT, plural, one {${message.singularPhrase}} other {${message.pluralPhrase}}}`
  if (message.context) key = message.context + '|' + key
  return key
}

const translate = (phrase = '', args?: object): string => {
  let translation = phrase
  if (currentLocale !== EN) {
    const possibleTranslation = currentLocaleTranslations[phrase]
    if (possibleTranslation) translation = possibleTranslation
    else logMissing(phrase)
  }
  return interpolate(translation, args)
}

const pluralTranslate = (message: Message, num: number, args?: object): string => {
  let translation = num === 1 ? message.singularPhrase : message.pluralPhrase
  if (currentLocale !== EN) {
    const tkey = getTranslationKey(message)
    const possibleTranslations = currentLocaleTranslations[tkey]
    if (possibleTranslations) {
      const cldr = pluralRules.select(num)
      if (possibleTranslations[cldr]) translation = possibleTranslations[cldr]
      else logMissing(tkey)
    } else {
      logMissing(tkey)
    }
  }
  return interpolate(translation || '', args)
}

const ntranslate = (singularPhrase: string, pluralPhrase: string, num: number, args?: object): string => {
  return pluralTranslate({ singularPhrase, pluralPhrase }, num, args)
}

/**
 * Simple translation with context
 *
 * @param context eg "A video that can be played"
 * @param phrase string to be translated, eg "Play %{name}"
 * @param args eg { name: "myvideo.mp4" }
 * @returns
 */
const ptranslate = (context: string, phrase: string, args?: object): string => {
  let translation = phrase
  if (currentLocale !== EN) {
    const tkey = getTranslationKey({ context, phrase })
    const possibleTranslation = currentLocaleTranslations[tkey]
    if (possibleTranslation) translation = possibleTranslation
    else logMissing(tkey)
  }
  return interpolate(translation, args)
}

/**
 * Function to help translate strings based on sigular/plural quantity.
 * @param {string} context Text explaining what is being translated - this is discarded.
 * @param {string} singularPhrase Singular version of text to translate, with placeholder. e.g. '{makersCount} Maker'
 * @param {string} pluralPhrase Plural version of text to translate, with placeholder. e.g. '{makersCount} Makers'
 * @param {number} num Quantity being counted to decide if singular or plural phrase is used. e.g. unitQuantity
 * @param {{key: number}} args Placeholder's value. e.g. { makersCount: unitQuantity }
 * @returns {string} the original phrase
 */
const nptranslate = (
  context: string,
  singularPhrase: string,
  pluralPhrase: string,
  num: number,
  args?: object,
): string => {
  return pluralTranslate({ context, singularPhrase, pluralPhrase }, num, args)
}

/**
 * Function to help the parser identify a string.
 * @param phrase the phrase to parse
 * @returns the original phrase
 */
const Ntranslate = (phrase: string): string => {
  return phrase
}

addTranslations(window.$intl)
if (window.$intl && window.$intl[UNDERSCORE_EN]) setLocale(UNDERSCORE_EN)
else setLocale(getHyphenatedCurrentLocale())

const getPriceFormatter = (price: number, currency: string): Intl.NumberFormat => {
  const options = {
    currency,
    style: 'currency',
  }
  return new Intl.NumberFormat(
    getHyphenatedCurrentLocale(),
    // We check if the number is an integer to decide whether
    // we should remove the zero decimals with the `maximumFractionDigits` option.
    Number.isInteger(price) ? { ...options, maximumFractionDigits: 0 } : options,
  )
}
/**
 * Function that formats a price using a currency
 * and the current locale.
 *
 * @param {number} price The price to format.
 * @param {string} currency The currency in which the price will be formatted.
 *
 * @returns {string} The formatted price.
 */

const translatePrice = (price: number, currency: string): string => {
  return getPriceFormatter(price, currency).format(price)
}

// Uncomment this for simple intl toolchain testing.
// const __ = translate
// __('Testing.... Do not translate this.')

export {
  translate as __,
  Ntranslate as N__,
  ntranslate as n__,
  nptranslate as np__,
  nptranslate,
  ntranslate,
  ptranslate as p__,
  ptranslate,
  translate,
  translatePrice,
}
