// @file Logger for all changes in pinia store.
// Inspired by https://github.com/cwdx/pinia-logger

import { isDebugMode } from '@@/bits/flip'
import { circularSafeStringify } from '@@/bits/json_stringify'
import { isEqual, pick } from 'lodash-es'
import type { PiniaPluginContext, StateTree, StoreGeneric, _ActionsTree, _StoreOnActionListenerContext } from 'pinia'
import { MutationType } from 'pinia'

export const shouldLog = isDebugMode

const cloneDeep = <T>(obj: T): T => {
  try {
    return JSON.parse(circularSafeStringify(obj))
  } catch {
    return { ...obj }
  }
}
const formatTime = (date = new Date()): string => {
  const hours = date.getHours().toString().padStart(2, '0')
  const minutes = date.getMinutes().toString().padStart(2, '0')
  const seconds = date.getSeconds().toString().padStart(2, '0')
  const milliseconds = date.getMilliseconds().toString()

  return `${hours}:${minutes}:${seconds}:${milliseconds}`
}

export interface PiniaLoggerOptions {
  disabled?: boolean
  expanded?: boolean
  showDuration?: boolean
  showStoreName?: boolean
  logErrors?: boolean
  filter?: (action: PiniaActionListenerContext) => boolean
}

export type PiniaActionListenerContext = _StoreOnActionListenerContext<StoreGeneric, string, _ActionsTree>

const defaultOptions: PiniaLoggerOptions = {
  logErrors: true,
  disabled: false,
  expanded: true,
  showStoreName: true,
  showDuration: false,
  filter: () => true,
}

interface LogOptions {
  startTime: number
  prevState: StateTree
  nextState: StateTree
  storeName: string
  isError: boolean
  error: any
  expanded: boolean
  actionName: string
  actionArgs: any
  showStoreName: boolean
  showDuration: boolean
}

const recentlyLoggedAction: Array<Partial<LogOptions>> = []

const logAction = (options: LogOptions): void => {
  const {
    startTime,
    prevState,
    nextState,
    storeName,
    isError,
    error,
    expanded,
    actionName,
    actionArgs,
    showStoreName,
    showDuration,
  } = options
  const endTime = Date.now()
  const duration = `${endTime - startTime}ms`
  const title = `action 🍍 ${showStoreName ? `[${storeName}] ` : ''}${actionName} ${
    isError ? `failed after ${duration} ` : ''
  }@ ${formatTime()}`

  console[expanded ? 'group' : 'groupCollapsed'](`%c${title}`, `font-weight: bold; ${isError ? 'color: #ed4981;' : ''}`) // goodcheck-disable-line
  console.log('%cprev state', 'font-weight: bold; color: grey;', prevState) // goodcheck-disable-line
  console.log('%caction', 'font-weight: bold; color: #69B7FF;', {
    type: actionName,
    args: actionArgs != null ? { ...actionArgs } : undefined,
    ...(showStoreName && { store: storeName }),
    ...(showDuration && { duration }),
    ...(isError && { error }),
  })
  console.log('%cnext state', 'font-weight: bold; color: #4caf50;', nextState) // goodcheck-disable-line
  console.groupCollapsed('%c trace', 'color: red; font-weight: bold') // goodcheck-disable-line
  console.log(new Error('Trace using Error.stack').stack) // goodcheck-disable-line
  console.groupCollapsed('Trace using console.trace')
  console.trace()
  console.groupEnd()
  console.groupEnd()
  console.groupEnd()
  recentlyLoggedAction.push(pick(options, ['prevState', 'nextState', 'storeName', 'isError', 'error']))
  // Remove the oldest log if we have more than 3 logs.
  if (recentlyLoggedAction.length > 3) {
    recentlyLoggedAction.shift()
  }
}

const logMutation = (options: LogOptions): void => {
  // Delay logging mutation because action also cause this log.
  // We want to log only direct mutation.
  // If we found the same changeset in the recently logged action, we skip this log.
  setTimeout(() => {
    const actionSignature = pick(options, ['prevState', 'nextState', 'storeName', 'isError', 'error'])
    if (recentlyLoggedAction.find((a) => isEqual(actionSignature, a)) != null) {
      return
    }
    logAction(options)
  }, 50)
}

const PiniaLogger =
  (config = defaultOptions) =>
  (ctx: PiniaPluginContext) => {
    const options: Required<PiniaLoggerOptions> = {
      ...defaultOptions,
      ...config,
    } as unknown as Required<PiniaLoggerOptions>

    if (options.disabled ?? false) return

    let prevState = cloneDeep(ctx.store.$state)

    // Log direct mutations to pinia store (i.e. not via actions but through state refs value assignment)
    ctx.store.$subscribe((mutation, state) => {
      const nextState = cloneDeep(state)
      if (isEqual(nextState, prevState)) return
      logMutation({
        startTime: Date.now(),
        prevState,
        nextState,
        storeName: ctx.store.$id,
        isError: false,
        error: undefined,
        expanded: options.expanded,
        actionName: mutation.type,
        actionArgs: mutation.type == MutationType.patchObject ? mutation.payload : [],
        showStoreName: options.showStoreName,
        showDuration: options.showDuration,
      })
      prevState = nextState
    })
    // Log actions on pinia store
    ctx.store.$onAction((action: PiniaActionListenerContext) => {
      const startTime = Date.now()
      const prevState = cloneDeep(ctx.store.$state)

      const log = (isError = false, error?: any): void => {
        logAction({
          startTime,
          prevState,
          nextState: cloneDeep(ctx.store.$state),
          storeName: action.store.$id,
          isError,
          error,
          expanded: options.expanded,
          actionName: action.name,
          actionArgs: action.args,
          showStoreName: options.showStoreName,
          showDuration: options.showDuration,
        })
      }

      action.after(() => {
        const canLog = options.filter?.(action) ?? false
        if (canLog) log()
      })

      if (options.logErrors) {
        action.onError((error) => {
          log(true, error)
        })
      }
    })
  }

export default PiniaLogger
