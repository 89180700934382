/**
 * @file Global numbers
 */

export const AUDIO_DURATION_SECONDS_TO_BYTES = {
  300: 34952534, // 5 minutes = 34.952534 MB
  600: 69905067, // 10 minutes = 69.905067 MB
  900: 104857600, // 15 minutes = 104.8576 MB
  1800: 209715200, // 30 minutes = 209.7152 MB
  3600: 419430400, // 60 minutes = 419.4304 MB
}
// Please change the constant at GoogleCloudResumableUploader.capped_file_size_limit
// if we do change this.
export const VIDEO_DURATION_SECONDS_TO_BYTES = {
  120: 858993460, // 2 minutes = 858.99346 MB
  300: 2147483648, // 5 minutes = 2147.483648 MB
  900: 6442450944, // 15 minutes = 6442.450944 MB
  1800: 12884901888, // 30 minutes = 12884.901888 MB
}

// Refer to MIN_PASSWORD_LENGTH and MAX_PASSWORD_LENGTH in app/models/user.rb
export const OLD_MIN_PASSWORD_LENGTH = 6 // Some older accounts have passwords that have not been changed since we increased the minimum length to 8
export const MIN_PASSWORD_LENGTH = 8
export const MAX_PASSWORD_LENGTH = 128
export const MAX_NAME_LENGTH = 110

// Refer to MAX_LENGTH in app/models/namespace.rb
export const MAX_NAMESPACE_LENGTH = 64

// Refer to BIO_MAX_LENGTH in app/models/library.rb
export const MAX_LIBRARY_BIO_LENGTH = 140

// Refer to MAX_BIO_LENGTH in app/models/user.rb
export const MAX_USER_BIO_LENGTH = 140

// Soft limit on frontend
export const MIN_SECTION_TITLE_LENGTH = 1
export const MAX_SECTION_TITLE_LENGTH = 128

// Character limits
// Refer to SUBJECT_CHARACTER_LIMIT and BODY_CHARACTER_LIMIT in app/models/wish.rb
export const POST_SUBJECT_CHARACTER_LIMIT = 500
export const POST_BODY_CHARACTER_LIMIT = 10000

export const POST_ATTACHMENT_ALTERNATIVE_TEXT_CHARACTER_LIMIT = 512

// Refer to BODY_CHARACTER_LIMIT in app/models/comment.rb
export const COMMENT_BODY_CHAR_LIMIT = 10000

// Refer to MAX_TENANT_DOMAIN_LENGTH in app/models/domain.rb minus by `.padlet.org` length
export const MAX_TENANT_SUBDOMAIN_LENGTH = 22
