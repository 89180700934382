/**
 * @file Handles DOM events and checks
 * */
import device from '@@/bits/device'

export let isDOMContentLoaded = false
export const DOMContentLoadedPromise: Promise<Event> = new Promise((resolve) => {
  if (document.readyState !== 'loading') {
    isDOMContentLoaded = true
    resolve(new Event('DOMContentLoaded'))
  }

  const onLoaded = (event): void => {
    document.removeEventListener('DOMContentLoaded', onLoaded)
    isDOMContentLoaded = true
    resolve(event)
  }

  document.addEventListener('DOMContentLoaded', onLoaded)
})

function isHtmlElementAnInputField(htmlElement: HTMLElement): boolean {
  const tagName = htmlElement?.tagName?.toUpperCase()
  if (!tagName) return false

  const isInputElement =
    ['TEXTAREA', 'INPUT', 'TRIX-EDITOR'].includes(tagName) ||
    htmlElement.contentEditable === 'true' ||
    htmlElement.isContentEditable
  // Trix keeps a copy of the editor in some sort of dom outside the current dom.

  const isLikelyTrixExternalDom = (): boolean => {
    return !document.documentElement.contains(htmlElement)
  }

  const isInTrixEditor = (): boolean => {
    const trixCollection = document.getElementsByTagName('trix-editor')
    for (let i = 0; i < trixCollection.length; i++) {
      const trixElement = trixCollection[i]
      if (trixElement.contains(htmlElement)) return true
    }
    return false
  }

  return isInputElement || isLikelyTrixExternalDom() || isInTrixEditor()
}

// Check if an event's target is an input field, copied from paste_to_post_mixin.ts since we are moving away from it
function isTargetAnInputField(eventTarget?: EventTarget | null): boolean {
  if (eventTarget == null) return false
  return isHtmlElementAnInputField(eventTarget as HTMLElement)
}

interface NonStandardClipboardEvent extends ClipboardEvent {
  originalTarget?: EventTarget
  explicitOriginalTarget?: EventTarget
}

function isEventOnAnInputField(event: NonStandardClipboardEvent): boolean {
  // Sometimes, target of pasting will be onto arbitrary #text target
  // In that case, we get originalTarget, or even explicitOriginalTarget
  return (
    isTargetAnInputField(event.target) ||
    isTargetAnInputField(event.originalTarget) ||
    isTargetAnInputField(event.explicitOriginalTarget)
  )
}

function isActiveElementAnInputField(): boolean {
  const activeElement = document?.activeElement
  return isHtmlElementAnInputField(activeElement as HTMLElement)
}
function isElementOnTopOfViewport(element): boolean {
  const rect = element.getBoundingClientRect()
  return rect.top < 0 && rect.bottom < 0
}
function isElementInViewport(element: Element): boolean {
  const rect = element.getBoundingClientRect()
  return !(
    rect.top > (window.innerHeight || document.documentElement.clientHeight) ||
    rect.right < 0 ||
    rect.bottom < 0 ||
    rect.left > (window.innerWidth || document.documentElement.clientWidth)
  )
}

/**
 * This function tries to get the closest element that is scrollable
 * by checking for elements that have either of the 3 tailwind classes that we use in our app.
 * If you need to check for other styles, you can add them to the scrollableStyleSelectors array.
 *
 * @param {HTMLElement} element - HTMLElement to get the nearest scrolling element for
 * @returns {HTMLElement | null} the nearest scrolling element or null
 */

function getNearestScrollingElement(element: HTMLElement | null): HTMLElement | null {
  if (element == null) return null

  const scrollableStyleSelectors = [
    '.overflow-auto',
    '.overflow-y-auto',
    '.overflow-x-auto',
    '.overflow-y-scroll',
    '[style*="overflow: auto"]',
    '[style*="overflow-y: auto"]',
    '[style*="overflow-x: auto"]',
  ]

  const closestElement = element?.closest(`:is(${scrollableStyleSelectors.join(', ')})`)
  if (closestElement != null) {
    return closestElement as HTMLElement
  }

  return null
}

function easeInOutCubic(t: number, b: number, c: number, d: number): number {
  t /= d / 2
  if (t < 1) return (c / 2) * t * t * t + b
  t -= 2
  return (c / 2) * (t * t * t + 2) + b
}

/**
 * This function uses the requestAnimationFrame API and a easeInOutCubic function
 * to simulate smooth scrolling. This is helpful because not all browsers support smooth scrolling,
 * and the native Web API for smooth scrolling does not allow for scroll speed/duration configuration.
 *
 * @param {HTMLElement} el - element to scroll
 * @param {number} to - target y-coordinate
 * @param {number} duration - animation duration of scrolling
 */

function smoothScrollTo(el: HTMLElement, to: number, duration: number): void {
  const from = el.scrollTop
  const distance = to - from

  let start: number
  function step(timestamp: number): void {
    if (start === undefined) start = timestamp
    const elapsed = timestamp - start

    // Apply the ease-in-out animation
    el.scroll(0, easeInOutCubic(elapsed, from, distance, duration))
    if (elapsed < duration) {
      window.requestAnimationFrame(step)
    }
  }

  window.requestAnimationFrame(step)
}

/**
 * Function to scroll to the top of a given element e.g. section.
 * This functions gets the nearest scrolling element that's wrapping the given element
 * and performs smooth scrolling on that element to reach the top of the given element.
 *
 * @param e - HTMLElement to scroll to
 * @param duration - animation duration of scrolling
 */
function scrollToTopOf(e: HTMLElement, duration: number): void {
  const elementInChargeOfScrolling = getNearestScrollingElement(e)

  if (elementInChargeOfScrolling != null) {
    const containerElementY = elementInChargeOfScrolling.getBoundingClientRect().top
    let curElement: HTMLElement | null = e
    let elementClientY = 0
    while (curElement != null && curElement !== elementInChargeOfScrolling) {
      elementClientY += curElement.offsetTop
      curElement = curElement.offsetParent as HTMLElement
    }
    const targetY = elementClientY - containerElementY
    smoothScrollTo(elementInChargeOfScrolling, targetY, duration)
  }
}

/**
 * Get the caret position (coordinates in pixels) in a textarea or input field
 * @see https://github.com/koddsson/textarea-caret-position
 */
function getTextCaretPosition(
  element: HTMLTextAreaElement | HTMLInputElement,
  selectionPosition: number,
  options = { relativeToViewport: false },
): { top: number; left: number; height: number } {
  // The mirror div will replicate the textarea's style
  const mirrorDiv = document.createElement('div')
  mirrorDiv.id = 'input-textarea-caret-position-mirror-div'
  document.body.appendChild(mirrorDiv)

  const mirrorDivStyle = mirrorDiv.style
  const computedStyle = window.getComputedStyle(element)
  const isInput = element.nodeName === 'INPUT'

  // Default textarea styles
  mirrorDivStyle.whiteSpace = 'pre-wrap'
  if (!isInput) mirrorDivStyle.wordWrap = 'break-word' // only for textarea-s

  // Position off-screen
  mirrorDivStyle.position = 'absolute' // required to return coordinates properly
  mirrorDivStyle.visibility = 'hidden' // not 'display: none' because we want rendering

  // Transfer the element's properties to the div
  // We'll copy the properties below into the mirror div.
  // Note that some browsers, such as Firefox, do not concatenate properties
  // into their shorthand (e.g. padding-top, padding-bottom etc. -> padding),
  // so we have to list every single property explicitly.
  const properties = [
    'direction', // RTL support
    'boxSizing',
    'width', // on Chrome and IE, exclude the scrollbar, so the mirror div wraps exactly as the textarea does
    'height',
    'overflowX',
    'overflowY', // copy the scrollbar for IE

    'borderTopWidth',
    'borderRightWidth',
    'borderBottomWidth',
    'borderLeftWidth',
    'borderStyle',

    'paddingTop',
    'paddingRight',
    'paddingBottom',
    'paddingLeft',

    // https://developer.mozilla.org/en-US/docs/Web/CSS/font
    'fontStyle',
    'fontVariant',
    'fontWeight',
    'fontStretch',
    'fontSize',
    'fontSizeAdjust',
    'lineHeight',
    'fontFamily',

    'textAlign',
    'textTransform',
    'textIndent',
    'textDecoration', // might not make a difference, but better be safe

    'letterSpacing',
    'wordSpacing',

    'tabSize',
    'MozTabSize',
  ]
  for (const prop of properties) {
    if (isInput && prop === 'lineHeight') {
      // Special case for <input>s because text is rendered centered and line height may be != height
      if (computedStyle.boxSizing === 'border-box') {
        const height = parseInt(computedStyle.height)
        const outerHeight =
          parseInt(computedStyle.paddingTop) +
          parseInt(computedStyle.paddingBottom) +
          parseInt(computedStyle.borderTopWidth) +
          parseInt(computedStyle.borderBottomWidth)
        const targetHeight = outerHeight + parseInt(computedStyle.lineHeight)
        if (height > targetHeight) {
          mirrorDivStyle.lineHeight = `${height - outerHeight}px`
        } else if (height === targetHeight) {
          mirrorDivStyle.lineHeight = computedStyle.lineHeight
        } else {
          mirrorDivStyle.lineHeight = '0'
        }
      } else {
        mirrorDivStyle.lineHeight = computedStyle.height
      }
    } else if (!isInput && prop === 'width' && computedStyle.boxSizing === 'border-box') {
      // With box-sizing: border-box we need to offset the size slightly inwards.  This small difference can compound
      // greatly in long textareas with lots of wrapping, leading to very innacurate results if not accounted for.
      // Firefox will return computed styles in floats, like `0.9px`, while chromium might return `1px` for the same element.
      // Either way we use `parseFloat` to turn `0.9px` into `0.9` and `1px` into `1`
      const totalBorderWidth = parseFloat(computedStyle.borderLeftWidth) + parseFloat(computedStyle.borderRightWidth)
      // When a vertical scrollbar is present it shrinks the content. We need to account for this by using clientWidth
      // instead of width in everything but Firefox. When we do that we also have to account for the border width.
      const width = device.firefox
        ? parseFloat(computedStyle[prop]) - totalBorderWidth
        : element.clientWidth + totalBorderWidth
      mirrorDivStyle[prop] = `${width}px`
    } else {
      mirrorDivStyle[prop] = computedStyle[prop]
    }
  }

  if (device.firefox) {
    // Firefox lies about the overflow property for textareas: https://bugzilla.mozilla.org/show_bug.cgi?id=984275
    if (element.scrollHeight > parseInt(computedStyle.height)) mirrorDivStyle.overflowY = 'scroll'
  } else {
    mirrorDivStyle.overflow = 'hidden' // for Chrome to not render a scrollbar; IE keeps overflowY = 'scroll'
  }

  mirrorDiv.textContent = element.value.substring(0, selectionPosition)
  // The second special handling for input type="text" vs textarea:
  // spaces need to be replaced with non-breaking spaces - http://stackoverflow.com/a/13402035/1269037
  if (isInput && mirrorDiv.textContent != null) mirrorDiv.textContent = mirrorDiv.textContent.replace(/\s/g, '\u00a0')

  const span = document.createElement('span')
  // Wrapping must be replicated *exactly*, including when a long word gets
  // onto the next line, with whitespace at the end of the line before (#7).
  // The  *only* reliable way to do that is to copy the *entire* rest of the
  // textarea's content into the <span> created at the caret position.
  // For inputs, just '.' would be enough, but no need to bother.
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  span.textContent = element.value.substring(selectionPosition) || '.' // || because a completely empty faux span doesn't render at all
  mirrorDiv.appendChild(span)

  const coordinates = {
    top: span.offsetTop + parseInt(computedStyle.borderTopWidth),
    left: span.offsetLeft + parseInt(computedStyle.borderLeftWidth),
    height: parseInt(computedStyle.lineHeight),
  }

  if (options.relativeToViewport) {
    const elementRect = element.getBoundingClientRect()
    coordinates.top += elementRect.top
    coordinates.left += elementRect.left
  }

  document.body.removeChild(mirrorDiv)

  return coordinates
}

/**
 * Resizes an HTML element to fit its parent element while maintaining its aspect ratio.
 * The element is scaled down by the maximum scale factor that fits within the parent element.
 * If the element cannot be scaled down to any of the provided scales, it is not scaled.
 * @param child - The HTML element to be resized.
 * @param parent - The HTML element that contains the child element.
 * @param scales - An array of scale factors to be applied to the child element.
 * @returns The scale factor that was applied to the child element.
 */
const resizeElementToFitParentWithScale = (child: HTMLElement, parent: HTMLElement, scales: number[]): number => {
  child.style.transformOrigin = 'center'

  const scaleX = parent.offsetWidth / child.offsetWidth
  const scaleY = parent.offsetHeight / child.offsetHeight

  const maxScale = Math.min(scaleX, scaleY)
  for (let i = scales.length - 1; i >= 0; i--) {
    const scale = scales[i]
    if (scale <= maxScale) {
      child.style.transform = `scale(${scale})`
      return scale
    }
  }
  return 1
}

/**
 * Checks if an element or any of its children are overflowing its parent element.
 * @param element - The element to check for overflow.
 * @param parent - The parent element to check against.
 * @returns True if the element or any of its children are overflowing the parent element, false otherwise.
 */
function isElementOrChildOverflowingParent(element: HTMLElement, parent: HTMLElement): boolean {
  const parentClientRect = parent.getBoundingClientRect()

  function isElementOverflowing(el: HTMLElement): boolean {
    const elClientRect = el.getBoundingClientRect()

    if (elClientRect.height > parentClientRect.height || elClientRect.width > parentClientRect.width) {
      return true
    }

    for (const child of Array.from(el.children) as HTMLElement[]) {
      if (isElementOverflowing(child)) {
        return true
      }
    }

    return false
  }

  return isElementOverflowing(element)
}

/**
 * Returns the default value of a CSS property for a given element.
 * @param nodeName The name of the element.
 * @param property The name of the CSS property.
 */
const getElementDefaultStyle = (nodeName: string, property: string): string => {
  const wrapper = document.createElement('div')
  const element = document.createElement(nodeName.toLowerCase())
  wrapper.appendChild(element)
  document.body.appendChild(wrapper)
  const style = window.getComputedStyle(element)
  const value = style.getPropertyValue(property)
  document.body.removeChild(wrapper)
  return value
}

export {
  getElementDefaultStyle,
  getNearestScrollingElement,
  getTextCaretPosition,
  isActiveElementAnInputField,
  isElementInViewport,
  isElementOnTopOfViewport,
  isElementOrChildOverflowingParent,
  isEventOnAnInputField,
  resizeElementToFitParentWithScale,
  scrollToTopOf,
}
