/**
 * @file Handles browser window-related operations.
 * */
import getCsrfToken from '@@/bits/csrf_token'
import device from '@@/bits/device'
import window, { browsingContextUid, ww } from '@@/bits/global'
import type { Wall as WallSnakeCase, WallCamelCase as Wall } from '@@/types'

export interface UrlOptions {
  protocol?: string
  host?: string
  path?: string
  search?: URLSearchParams | Record<string, string> | string[][] | string
  searchParams?: URLSearchParams | Record<string, string> | string[][] | string
  hash?: string
}

interface CurrentUrlOptions {
  withQueryString?: boolean
}

export function currentProtocol(): string {
  return window.location.protocol
}

export function buildUrl(base: string, path: string): string {
  return new URL(path, base).toString()
}

export function getPathFromUrl(url: string): string {
  return new URL(url).pathname
}

export function getPathPrefixFromUrl(url: string): string {
  const path = new URL(url).pathname
  return path.substr(0, path.lastIndexOf('/'))
}

export function reload(): void {
  window.location.reload()
}

export function goBack(): void {
  window.history.back()
}

/**
 * Returns current browser url. Returns with query string by default.
 *
 * @param withQueryString Set to true by default
 */
export function currentUrl(options: CurrentUrlOptions = { withQueryString: true }): string {
  return options.withQueryString ? window.location.href : window.location.href.split('?')[0]
}

export function currentPath(): string {
  return window.location.pathname
}

export function currentPathWithoutLeadingSlash(): string {
  return window.location.pathname.replace('/', '')
}

/**
 * @param pathname optional string pathname instead of getting it from browser URL
 * e.g. /dashboard/gallery/grid will return ['dashboard','gallery','grid']
 */
export function getPathnameItems(pathname?: string): string[] {
  const urlPathname = pathname || currentPath()
  const pathnameItems = (urlPathname.startsWith('/') ? urlPathname.slice(1) : urlPathname).split('/')
  return pathnameItems
}

export function currentHostWithProtocol(): string {
  return `${window.location.protocol}//${window.location.host}`
}

export function currentHostname(): string {
  return window.location.hostname
}

export function buildUrlFromPath(path: string): string {
  return buildUrl(currentHostWithProtocol(), path)
}

export function modifyCurrentUrl(urlOptions: UrlOptions): string {
  const host = urlOptions.host || window.location.host
  return buildUrl(`${currentProtocol()}//${host}`, currentPath())
}

/**
 * Receives an URL and transforms it without modifying it.
 *
 * @param {URL | string} url A valid URL to transform
 * @param {string} urlOptions.protocol Defaults to URL protocol if not specified.
 * @param {string} urlOptions.host Defaults to URL host if not specified.
 * @param {string} urlOptions.path Defaults to URL path if not specified.
 * @param {URLSearchParams | Record<string, string> | string[][] | string} urlOptions.search Sets the search params and overrides existing search params if specified. If this is specified urlOptions.searchParams is ignored.
 * @param {URLSearchParams | Record<string, string> | string[][] | string} urlOptions.searchParams Modifies the existing search params if specified. If urlOptions.search is specified this is ignored.
 *
 * @returns The transformed URL
 */
export function transformUrl(url: URL | string, urlOptions: UrlOptions = {}): string {
  const oldUrl = new URL(url.toString())
  const protocol = urlOptions.protocol ? `${urlOptions.protocol}` : oldUrl.protocol
  const path = urlOptions.path ? urlOptions.path : oldUrl.pathname
  const base = urlOptions.host ? `${protocol}//${urlOptions.host}` : `${protocol}//${oldUrl.host}`
  const newUrl = new URL(path, base)
  let { search: newSearch = undefined, searchParams: newSearchParams = undefined, hash } = { ...urlOptions }
  if (newSearch) {
    if (!(newSearch instanceof URLSearchParams)) {
      newSearch = new URLSearchParams(newSearch)
    }
    newUrl.search = newSearch.toString()
  } else {
    const urlSearchParams = oldUrl.searchParams
    if (newSearchParams) {
      if (!(newSearchParams instanceof URLSearchParams)) {
        newSearchParams = new URLSearchParams(newSearchParams)
      }
      for (const [key, value] of newSearchParams.entries()) {
        !value ? urlSearchParams.delete(key) : urlSearchParams.set(key, value)
      }
    }
    newUrl.search = urlSearchParams.toString()
  }
  if (hash != null && hash !== '') {
    newUrl.hash = hash
  }
  return newUrl.toString()
}

/**
 * Transforms the current URL without modifying it.
 *
 * @param {CurrentUrlOptions} currentUrlOptions Options to generate the current URL.
 * @param {UrlOptions} urlOptions Options to transform the current URL.
 *
 * @returns The current URL, transformed.
 */
export function transformCurrentUrl(currentUrlOptions: CurrentUrlOptions, urlOptions: UrlOptions): string {
  return transformUrl(currentUrl(currentUrlOptions), urlOptions)
}

export function getHostnameFromUrl(url: string): string {
  return new URL(url).hostname
}

function stripPadletUrlSubdomain(url: string): string {
  return url.substr(url.indexOf('padlet'), url.length)
}

export function doUrlsSharePathPrefix(url1: string, url2: string): boolean {
  return getPathPrefixFromUrl(url1) === getPathPrefixFromUrl(url2)
}

export function isCurrentUrlParentOf(url: string): boolean {
  return stripPadletUrlSubdomain(url).includes(stripPadletUrlSubdomain(currentUrl({ withQueryString: true })))
}

export function isUrlParentOf(possibleParentUrl: string, url: string): boolean {
  return stripPadletUrlSubdomain(possibleParentUrl).includes(stripPadletUrlSubdomain(url))
}

interface RedirectOptions {
  method?: string
  target?: string
}

export function navigateTo(url: string, options: RedirectOptions = {}): void {
  const { method, target } = options

  if (method && method.toLowerCase() !== 'get') {
    const fakeForm = document.createElement('form')

    const csrfTokenInput = document.createElement('input')
    csrfTokenInput.type = 'hidden'
    csrfTokenInput.name = 'authenticity_token'
    csrfTokenInput.value = getCsrfToken()

    // Arvo sets X-UID header by default. Since this is not using Arvo, we have to set it manually
    const clientUidInput = document.createElement('input')
    clientUidInput.type = 'hidden'
    clientUidInput.name = 'X-UID'
    clientUidInput.value = window?.ww?.uid || browsingContextUid

    const submitButton = document.createElement('button')
    submitButton.type = 'submit'

    fakeForm.action = url
    fakeForm.method = method
    fakeForm.appendChild(csrfTokenInput)
    fakeForm.appendChild(clientUidInput)
    fakeForm.appendChild(submitButton)
    document.body.appendChild(fakeForm)

    fakeForm.submit()
  } else if (device.nwjs) {
    ww.navigateDesktopApp?.(url, target)
  } else if (device.app) {
    window.location.href = url
  } else if (target) {
    window.open(url, target)
  } else {
    window.location.href = url
  }
}

export function navigateToWall(wall: Wall | WallSnakeCase, hrefTarget = '_blank'): void {
  navigateTo(wall.links.show ?? `/${wall.address}`, { target: hrefTarget })
}

export function navigateToMap({ lat, lng, name }: { lat: number; lng: number; name: string }): void {
  const searchQuery = name ? encodeURIComponent(name) : `${lat},${lng}`
  if (device.ios) {
    window.open(`https://maps.apple.com/?q=${searchQuery}&sll=${lat},${lng}`)
  } else {
    window.open('https://www.google.com/maps/search/?api=1&query=' + searchQuery)
  }
}

export function authWithReferrerUrl(referrer: string): string {
  return transformCurrentUrl(
    {},
    {
      path: '/auth',
      search: {
        referrer,
      },
    },
  )
}

export function signUpWithReferrerUrl(referrer: string): string {
  return transformCurrentUrl(
    {},
    {
      path: '/auth/signup',
      search: {
        referrer,
      },
    },
  )
}

export function loginWithReferrerUrl(referrer: string): string {
  return transformCurrentUrl(
    {},
    {
      path: '/auth/login',
      search: {
        referrer,
      },
    },
  )
}

/**
 * Clear a specific search param with the given key and navigate the browser by default, with the option to only replace current URL.
 * @param key key of the search param to clear
 * @param navigate defaults to true. Set to false to replace the current URL without navigating
 * @returns value of the cleared search param
 */
export function clearSearchParam(key: string, navigate = true): string | null {
  if (!('URLSearchParams' in window)) return null
  const location = (window as Window).location

  const searchParams = new URLSearchParams(location.search)
  const value = searchParams.get(key)
  if (value === null) return null

  searchParams.delete(key)
  const newUrl = new URL(location.href)
  newUrl.search = searchParams.toString()
  if (navigate) {
    history.replaceState(null, '', newUrl.toString())
  } else {
    history.pushState(null, '', newUrl.toString())
  }
  return value
}

/**
 * @see {@link clearSearchParam}
 */
export function getAndClearSearchParam(key: string, navigate = true): string | null {
  return clearSearchParam(key, navigate)
}

/**
 * Set a specific search param with key and value and navigate the browser by default, with the option to only replace current URL.
 * @param key key of the search param to set
 * @param value value of the search param to set
 * @param navigate defaults to true. Set to false to replace the current URL without navigating
 * @returns value of the newly set search param
 */
export function setSearchParam(key: string, value: string, navigate = true): string | null {
  if (!('URLSearchParams' in window)) return null
  const location = (window as Window).location

  const searchParams = new URLSearchParams(location.search)
  searchParams.set(key, value)
  const newUrl = new URL(location.href)
  newUrl.search = searchParams.toString()
  if (navigate) {
    history.replaceState(null, '', newUrl.toString())
  } else {
    history.pushState(null, '', newUrl.toString())
  }
  return value
}

export function setQueryParamWithoutReloading(queryParams: string): void {
  const newUrl = window.location.href.split('?')[0] + '?' + queryParams
  window.history.pushState(null, '', newUrl)
}

export function clearQueryParamsWithoutReloading(): void {
  const newUrl = currentUrl({ withQueryString: false })
  window.history.pushState(null, '', newUrl)
}

export function setLocationHash(hash: string): void {
  window.location.hash = hash
}

export function goBackUsingReferrer(): void {
  const dashboardUrl = buildUrlFromPath('dashboard')
  const referrer = document.referrer
  // If there's no referrer or the referrer is an external URL (hostnames
  // don't match), redirect to dashboard.
  if (!referrer || getHostnameFromUrl(referrer) !== currentHostname()) {
    navigateTo(dashboardUrl)
    return
  }
  // If the current and previous URLs share the same path prefix (e.g /dashboard/settings/basic
  // and /dashboard/settings/delete), redirect to dashboard.
  if (doUrlsSharePathPrefix(referrer, currentUrl({ withQueryString: false }))) {
    navigateTo(dashboardUrl)
    return
  }
  // If the history length is 1 (means that the current page was
  // open through a link in a new tab), redirect to dashboard
  if (window.history.length === 1) {
    navigateTo(dashboardUrl)
    return
  }
  // Else, go back using browser's history.
  goBack()
}

export function getSearchParam(key: string): string | null {
  if (!('URLSearchParams' in window)) return null
  const location = (window as Window).location

  const searchParams = new URLSearchParams(location.search)
  return searchParams.get(key)
}

// Get hash of search params. Takes the last value if the param is repeated.
export function getSearchParams(urlString: string | null = null): Record<string, string> {
  let searchParams: URLSearchParams
  if (urlString) {
    searchParams = new URL(urlString).searchParams
  } else {
    const location = (window as Window).location
    searchParams = new URLSearchParams(location.search)
  }

  const params = {}
  searchParams.forEach(function (value, key) {
    params[key] = value
  })
  return params
}

export function checkIfPageIsAccessedByReload(): boolean {
  if (window.performance?.navigation?.type === 1) return true
  if (window.performance?.getEntriesByType == null) return false
  return window.performance
    .getEntriesByType('navigation')
    .map((nav) => (nav as any).type)
    .includes('reload')
}

export function isSearchParamTruthy(param: string | null): boolean {
  return param === 'true' || param === '1'
}
